import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Introduction from "../components/introduction"
import Spacer from "../components/spacer"
import WorkGrid from "../components/work-grid"
import ImageGridItem from "../components/image-grid-item"
import CenteredSlider from "../components/centered-slider"
import CenteredSlide from "../components/centered-slide"

class WorkPage extends Component {
  render() {
    const data = this.props.data

    return (
      <Layout background={ true} >
        <SEO title="Our Work" />
        <Introduction 
          title="Our Work"
        />
        <Spacer space="2" />
        <WorkGrid>       
          {/*<ImageGridItem width={12} image={ '../mourenne.jpg' } subtitle="Shopify" title="Mourènne" link="https://www.mourenne.com/" showLink="true" />*/}
          <ImageGridItem width={7} image={ '../sarrieri.jpg' } subtitle="Shopify" title="Sarrieri" link="https://sarrieri.com/" showLink="true" />
          <ImageGridItem width={5} image={ '../tc24.jpg' } subtitle="WooCommerce" title="Telecare24" link="https://www.telecare24.co.uk/" showLink="true" />      
          <ImageGridItem width={5} image={ '../social-game.jpg' } subtitle="Shopify" title="The Social Game" link="https://thesocialgame.co.uk/" showLink="true" />      
          <ImageGridItem width={7} image={ '../grounded.jpg' } subtitle="Shopify" title="Grounded Coffee" link="https://www.grounded-coffee.com/" showLink="true" />          
          <ImageGridItem width={12} image={ '../performance-comms.jpg' } subtitle="WordPress" title="Performance Communications" link="https://performancecomms.com/" showLink="true" />      
          <ImageGridItem width={7} image={ '../envista.jpg' } subtitle="Shopify" title="Envista" link="https://envista.co.uk/" showLink="true" />      
          <ImageGridItem width={5} image={ '../first-wealth.jpg' } subtitle="WordPress" title="First Wealth" link="https://www.firstwealth.co.uk/" showLink="true" />
          <ImageGridItem width={5} image={ '../dandy.jpg' } subtitle="Shopify" title="Dandy Wellness" link="https://www.dandy-wellness.com/" showLink="true" />
          <ImageGridItem width={7} image={ '../mad.jpg' } subtitle="WordPress" title="Mystery Away Days" link="https://mysteryawaydays.co.uk/" showLink="true" />
          <ImageGridItem width={7} image={ '../dbm.jpg' } subtitle="WordPress" title="DBM Group" link="https://www.dbmgroup.com/" showLink="true" />
          <ImageGridItem width={5} image={ '../kushae.jpg' } subtitle="Shopify" title="Kushae" link="https://kushae.com/" showLink="true" />
          <ImageGridItem width={5} image={ '../feria.jpg' } subtitle="WordPress" title="Feria Urbanism" link="https://feria-urbanism.com/" showLink="true" />
          <ImageGridItem width={7} image={ '../thrust.jpg' } subtitle="Craft CMS" title="Thrust Carbon" link="https://thrustcarbon.com/" showLink="true" />
          <ImageGridItem width={7} image={ '../surfdurt.jpg' } subtitle="Shopify" title="SurfDurt" link="https://www.surfdurt.com/" showLink="true" />
          <ImageGridItem width={5} image={ '../magic.jpg' } subtitle="Shopify" title="The 1914" link="https://the1914.com/" showLink="true" />
          <ImageGridItem width={5} image={ '../niche.jpg' } subtitle="Wordpress" title="Niche FS" link="https://nichefs.co.uk/" showLink="true" />
          <ImageGridItem width={7} image={ '../pi.jpg' } subtitle="Shopify" title="Pineapple Island" link="https://pineapple-island.com/" showLink="true" />
          <ImageGridItem width={7} image={ '../allett.jpg' } subtitle="Shopify" title="Allett Wallets" link="https://www.all-ett.com/" showLink="true" />          
          <ImageGridItem width={5} image={ '../shy.jpg' } subtitle="Shopify" title="Shy Creation" link="https://shycreation.com/" showLink="true" />
          {/*<ImageGridItem width={5} image={ '../bonsucro.jpg' } subtitle="WordPress" title="Bonsucro" link="https://www.bonsucro.com/" showLink="true" />*/}
          {/*<ImageGridItem width={7} image={ '../connock.jpg' } subtitle="Shopify" title="Connock London" link="https://www.connocklondon.co.uk/" showLink="true" />*/}
          {/*<ImageGridItem width={7} image={ '../mule.jpg' } subtitle="Shopify" title="Mule Carry" link="https://mulecarry.com/" showLink="true" />*/}

        </WorkGrid>
        <Spacer space="2" />
        <CenteredSlider>
          {data.allWordpressPost.edges.map(({ node }) => (
            <CenteredSlide key={node.id} title={node.title} image={ node.featured_media.localFile.childImageSharp.original.src } link={node.path} />
          ))}
        </CenteredSlider>         
      </Layout>
    )
  }
}

export default WorkPage

export const pageQuery = graphql`
  query {
    allWordpressPost(sort: {fields: [date], order: DESC}) {
      edges {
        node {
          id
          slug
          path
          title
          featured_media {
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }        
        }
      }
    }
  }
`