import React from "react"

const WorkGrid = ({ children }) => (
  <>
    <div className="row shrink-width grid-items">
      {children}
    </div>
  </>
)

export default WorkGrid